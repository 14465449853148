jQuery(document).ready(function () {
    jQuery('#check-menu').click(function () {
        jQuery("body, html").toggleClass('hidden');
    });
    jQuery('.menu > li a').click(function(){
        jQuery('#check-menu').prop('checked', false);
        jQuery("body, html").toggleClass('hidden');
    });
    jQuery('#main').fullpage({
        menu: '.menu',
        anchors: ['mainSection', 'aboutUsSection', 'aboutUsSection', 'infrastructureSection', 'infrastructureSection', 'infrastructureSection', 'infrastructureSection', 'infrastructureSection', 'contactsSection'],
        lazyLoading: true,
        responsiveWidth: 1024
    });
    jQuery('.infrastructure-slider').slick({
        slidesToShow: 4,
        responsive: [
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 720,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: true
                }
            }
        ]
    });
    jQuery('.simplebox').simplebox({
        overlay: {
            color: 'black',
            opacity: 0.7
        },
        linkClose: '.close-modal'
    });
});
